/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const CheckboxOnIconSvg = forwardRef((props, svgRef) => (
	<svg
		data-name="Livello 1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M20.4 21.5H3.6c-.61 0-1.1-.49-1.1-1.1V3.6c0-.61.49-1.1 1.1-1.1h16.8c.61 0 1.1.49 1.1 1.1v16.8c0 .61-.49 1.1-1.1 1.1zM3.6 3.5c-.06 0-.1.04-.1.1v16.8c0 .05.04.1.1.1h16.8a.1.1 0 00.1-.1V3.6c0-.06-.04-.1-.1-.1H3.6z" />
		<path d="M10 16a.47.47 0 01-.35-.15l-3-3c-.2-.2-.2-.51 0-.71s.51-.2.71 0l2.65 2.65 6.65-6.65c.2-.2.51-.2.71 0s.2.51 0 .71l-7 7c-.1.1-.23.15-.35.15z" />
	</svg>
))
CheckboxOnIconSvg.displayName = 'CheckboxOnIconSvg'

const CheckboxOnIcon = forwardRef((props, ref) => <Icon component={CheckboxOnIconSvg} ref={ref} {...props} />)
CheckboxOnIcon.displayName = 'CheckboxOnIcon'

CheckboxOnIcon.defaultProps = {
	...Icon.defaultProps,
}
CheckboxOnIcon.propTypes = {
	...Icon.propTypes,
}

export default CheckboxOnIcon
export { CheckboxOnIconSvg }
